import {listen} from "../lib/component.js";
import Component from "./_component.js";

export default class Details extends Component {

	constructor($, $$, props) {
		super(...arguments);
		listen($, "toggle", () => this.toggle());
	}

	toggle() {
		this.$.open && this.$.querySelector("[autofocus]")?.focus()
	}

}
