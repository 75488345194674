import {listen} from "../lib/component.js";
import Controller from "./_controller.js";

export default class IndexPage extends Controller {

	initialize() {

		const categoryForm = this.$$.categoryForm.component;
		const indexForm = this.$$.indexForm.component;

		const params = new URLSearchParams(location.search);
		if(params.has("token")) {
				categoryForm.ƒ.token.value = params.get("token");
				categoryForm.props.details.open = true;
				categoryForm.$.requestSubmit();
		}

		listen(categoryForm.$, "@category-form:load", event => {
			indexForm.addCategory(event.detail.category);
		});

		listen(
			indexForm.$$.invoiceDetails,
			"toggle",
			() => indexForm.$$.invoiceCountrySelect.component.render(),
			{once: true}
		);

	}

}
