import {listen} from "../lib/component.js";
import Form from "./_form.js";

const $SOCIAL_PREFIXES = ["bluesky", "mastodon", "discord"];

export default class AttendeeForm extends Form {

	constructor($, $$, props) {

		super(...arguments);

		if($$.buttons != null) {
			this.$.style.setProperty("--attendee-form__buttons-height", `${this.$$.buttons.clientHeight}px`);
			listen($$.buttons, "click", event => {
				const target = event.target.closest("[data-action]");
				if(target == null) {
					return;
				}
				this.setup(target.dataset.action);
			});
		}

	}

	async setup(action) {
		await this.$$.buttons.animate({opacity: 0}, {duration: 200, easing: "ease-out", fillMode: "forwards"}).finished;
		this.$$.buttons.hide();
		switch(action) {
			case "fromcontact":
				this.$$.canvas.disabled = false;
				this.$$.canvas.show();
				this.dispatch("fromcontact");
				break;
			case "frominput":
				this.$$.canvas.disabled = false;
				this.$$.canvas.show();
				break;
			case "postpone":
				// @todo: Show message.
				this.$$.canvas.hide();
				this.$$.canvas.disabled = true;
				break;
		}
	}

	serialize() {

		const socials = [];
		for(const prefix of $SOCIAL_PREFIXES) {
			const handle = String.fromInput(this.ƒ[prefix]);
			handle != null && socials.push([prefix, handle].join(":"));
		}

		return {
			ticketId:    String.fromInput(this.ƒ.ticketId),
			name:        String.fromInput(this.ƒ.name),
			email:       String.fromInput(this.ƒ.email),
			affiliation: String.fromInput(this.ƒ.affiliation),
			pronouns:    String.fromInput(this.ƒ.pronouns),
			diet:        String.fromInput(this.ƒ.diet),
			hidden:			 !this.ƒ.hidden.checked,
			socials
		}

	}

	async submit() {
		if(this.$$.canvas.disabled) {
			return;
		}
		this.$$.button?.setState("pending");
		try {
			await Promise.all([super.submit(), sleep(500)]);
			if(this.data?.id != null) {
				this.$.method = "PATCH";
				this.$.action = `/api/attendees/${this.data.id}`;
				this.ƒ.ticketId.remove();
			}
		}
		finally {
			this.$$.button?.removeState();
		}
	}

}
