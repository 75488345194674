import {fetchJSON} from "../lib/component.js";
import Component from "./_component.js";

const url = new URL("/assets/data/iso-3166-alpha-2.json", location.origin);

export default class CountrySelect extends Component {

	constructor($, $$, props) {
		props = Object.assign({render: false}, props);
		super(...arguments);
		props.render && this.render();
	}

	async render(force=false) {
		if(!!this.rendered && !force) {
			return;
		}
		this.$.append(document.createElement("hr"));
		Object.entries(await this.getCountries()).forEach(([code, name]) => {
			const selected = code == this.props.value;
			this.$.options.add(new Option(name, code, selected, selected));
		});
		this.rendered = true;
	}

	async getCountries() {
		try {
			return await fetchJSON("GET", url, {throwOn404: true});
		}
		catch {} // Fail silently.
	}

}
