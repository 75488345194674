import {listen} from "../lib/component.js";
import Component from "./_component.js";

export default class Controller extends Component {

	constructor($, $$, props) {
		super(...arguments);
		this.initialize != null && listen(document, "DOMContentLoaded", () => this.initialize());
	}

}
