import Form from "./_form.js";

export default class OrderPaymentForm extends Form {

	get action() {
		const id = location.pathname.split("/").pop();
		return new URL(`/api/orders/${id}/payments`, location.origin);
	}

	serialize() {
		// @debug:
		return {
			methodId: undefined,
			issuerId: undefined
		}
	}

	async submit() {
		this.$$.button.setState("pending");
		try {
			await super.submit();
			location.assign(this.data.checkoutUrl);
		}
		finally {
			this.$$.button.removeState();
		}
	}

}
