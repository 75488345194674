// @template: `export {default as ${file.name.pascalCase}} from "./${file.base}";`

export {default as AttendeeForm} from "./attendee-form.js";
export {default as AttendeePage} from "./attendee-page.js";
export {default as CategoryForm} from "./category-form.js";
export {default as CountrySelect} from "./country-select.js";
export {default as Details} from "./details.js";
export {default as ErrorMessage} from "./error-message.js";
export {default as IndexForm} from "./index-form.js";
export {default as IndexPage} from "./index-page.js";
export {default as OrderPage} from "./order-page.js";
export {default as OrderPaymentForm} from "./order-payment-form.js";
