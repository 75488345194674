import {listen, fetchJSON} from "../lib/component.js";
import Component from "./_component.js";

export default class Form extends Component {

	constructor($, $$, props) {

		super(...arguments);

		// Get the method from the form _attribute_ (not the property), as the native FormElement.method property only supports GET and POST:
		Object.defineProperty($, "method", {
			get: () => $.getAttribute("method") ?? "GET",
			set: value => $.setAttribute("method", value)
		});

		$.noValidate = true;

		listen($, "submit", () => !this.frozen && this.validate() && this.submit(), {preventDefault: true});

	}

	getElementsByName(name) {
		return Array.from(document.getElementsByName(name)).filter(element => this.$.contains(element));
	}

	getElementByName(name) {
		return this.getElementsByName(name).shift();
	}

	ƒƒ = new Proxy(this, {get: (_, name) => this.getElementsByName(name)});
	ƒ =  new Proxy(this, {get: (_, name) => this.getElementByName(name)});

	get action() {
		return new URL(this.$.action);
	}

	validate() {
		const valid = this.$.checkValidity();
		this.$.setState(valid ? "valid" : "invalid");
		!valid && this.$.querySelector(":invalid").scrollIntoViewIfNeeded(false);
		return valid;
	}

	async submit() {
		this.frozen = true;
		const request = {method: this.$.method, url: this.action};
		if(this.serialize != null) {
			switch(request.method.toUpperCase()) {
				case "GET":
				case "HEAD":
				case "OPTIONS":
					Object.entries(this.serialize()).forEach(([key, value]) => request.url.searchParams.set(key, value));
					break;
				case "DELETE":
				case "PATCH":
				case "POST":
				case "PUT":
					request.body = this.serialize();
					break;
			}
		}
		try {
			this.data = await fetchJSON(request.method, request.url, {data: request.body});
		}
		catch(error) {
			this.dispatch("error", {error}, {global: true});
			throw(error);
		}
		finally {
			this.frozen = false;
		}
	}

}
