import {getElements, getProps} from "../lib/component.js";

export default class Component {

	static fromElement($) {

		// Instantiate the component:
		const name =  this.name.toKebabCase();
		const $$ =    getElements($, name);
		const props = getProps($, name);

		console.groupCollapsed(`%c${this.name}`, "color: cornflowerblue");
		console.log("%c$", "font-weight: bold", $);
		console.log("%c$$", "font-weight: bold", $$);
		console.log("%cprops", "font-weight: bold", props);
		console.groupEnd();

		// Clean up the component HTML:
		$.removeAttribute("@component");
		Object.entries($$).forEach(([key, element]) => element.removeAttribute(`@${name}:${key.toKebabCase()}`));
		Object.keys(props).forEach(key => $.removeAttribute(`@${name}:${key.toKebabCase()}`));

		return new this($, $$, props);

	}

	constructor($, $$, props, this_) {
		this.$ = $;
		this.$$ = $$;
		this.props = props;
		Object.assign(this, this_);
		this.dispatch("instantiation");
	}

	dispatch(types, detail, options={}) {
		const options_ = {global: false, bubbles: true, detail, ...options};
		const prefix = `@${options_.global ? "*" : this.constructor.name.toKebabCase()}:`;
		types.split(" ").forEach(type => this.$.dispatchEvent(new CustomEvent(`${prefix}${type}`, options_)));
	}

};
