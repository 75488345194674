import * as constructors from "./components/__index__.js";

for(const obj of [document, HTMLElement.prototype]) {
	Object.defineProperty(obj, "component", {
		get: function() {
			return components.get(this);
		}
	});
}

const components = new WeakMap();

const instantiate = () => {
	const query = './/*[@*[name()="@component"]]';
	const result = new XPathEvaluator().evaluate(query, document, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE);
	for(const root of result) {
		const name = root.getAttribute("@component").toPascalCase()
		const constructor = constructors[name];
		if(constructor == null) {
			throw new Error(`Component class [${name}] does not exist.`);
		}
		const component = constructor.fromElement(root);
		components.set(component.$, component);
	}
};

// Instantiate components now:
instantiate();

// Instantiate new components when they are added to the document:
new MutationObserver(mutations => mutations.some(mutation => !!mutation.addedNodes.length) && instantiate())
.observe(document.body, {childList: true, subtree: true});
