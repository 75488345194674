import {listen} from "../lib/component.js";
import Form from "./_form.js";

export default class CategoryForm extends Form {

	constructor($, $$, props) {
		super(...arguments);
		listen(this.ƒ.token, "input", () => this.ƒ.token.value = this.ƒ.token.value.replace(" ", "-"));
	}

	serialize() {
		return {token: String.fromInput(this.ƒ.token)};
	}

	invalidate() {
		const input = this.ƒ.token;
		input.setCustomValidity("$TICKET_CATEGORY_DOES_NOT_EXIST");
		this.validate();
		this.$$.button.setState("warning");
		listen(input, "input", () => this.reset(), {once: true});
	}

	reset() {
		this.frozen = false;
		this.ƒ.token.setCustomValidity("")
		this.$$.button.removeState();
		this.$$.messageInvalid.hide();
		this.$$.messageSoldOut.hide();
	}

	async submit() {
		// this.$$.messageInvalid.hide();
		// this.$$.messageSoldOut.hide();
		this.$$.button.setState("pending");
		const field = document.querySelector(`[data-token="${this.ƒ.token.value.toLowerCase()}"]`);
		if(field != null) {
			this.reset();
			field.querySelector('input[name="quantity"]').focus();
			field.dataset.state = "highlight";
			return;
		}
		try {
			await Promise.all([super.submit(), sleep(500)]);
		}
		finally {
			this.$$.button.removeState();
		}
		if(this.data == null) {
			// The requested ticket category does not exist.
			this.invalidate();
			this.$$.messageInvalid.show();
		}
		else if(!this.data.ticketCountAvailable) {
			// The requested ticket category exists but has sold out.
			this.invalidate();
			this.$$.messageSoldOut.show();
		}
		else {
			this.frozen = true;
			this.$$.button.setState("success");
			this.dispatch("load", {category: this.data});
			listen(this.ƒ.token, "input", () => this.reset(), {once: true});
		}
	}

}
