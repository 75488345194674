import {listen, fetchJSON} from "../lib/component.js";
import Template from "../lib/template.js";
import Controller from "./_controller.js";

export default class OrderPage extends Controller {

	constructor($, $$, props) {

		super(...arguments, {
			cardTemplate: new Template($$.cardTemplate),
			attendeeListTemplate: new Template($$.attendeeListTemplate)
		});

		listen($$.button, "click", async () => this.submit());

		listen($, "@attendee-form:fromcontact", event => this.fromContact(event.target.component));

	}

	async initialize() {

		const id = location.pathname.split("/").pop();
		const url = new URL(`/api/orders/${id}`, location.origin);
		try {
			const [order] = await Promise.all([fetchJSON("GET", url, {throwOn404: true}), sleep(500)]);
			this.order = order;
		}
		catch(error) {
			this.dispatch("error", {error}, {global: true});
			throw(error);
		}

		let attendeeCount = 0;
		const attendees = this.order.categories.map(category => (
			category.attendees.map(attendee => {
				attendee.id != null && attendeeCount++;
				return {...attendee, categoryName: category.name};
			})
		))
		.flat();
		this.cardTemplate(this.$$.cardCanvas, this.order);
		this.attendeeListTemplate(this.$$.attendeeListCanvas, attendees);

		const steps = this.$$.progress.children;
		requestAnimationFrame(async () => {
			steps[0].setState("complete");
			attendeeCount == attendees.length && await sleep(200, () => steps[1].setState("complete"));
			this.order.paymentComplete  && await sleep(200, () => steps[2].setState("complete"));
		});

	}

	fromContact(form) {
		form.ƒ.name.value = this.order.name;
		form.ƒ.email.value = this.order.email;
		form.ƒ.affiliation.value = this.order.invoiceName ?? "";
		Array.from(this.$.querySelectorAll('button[data-action="fromcontact"]')).forEach(button => button.disabled = true);
	}

	async submit() {
		this.$$.button.setState("pending");
		try {
			const forms = Array.from(this.$$.attendeeListCanvas.querySelectorAll("form"));
			await Promise.all(forms.map(form => form.component.validate() && form.component.submit()));
		}
		finally {
			this.$$.button.removeState();
		}
	}

}
