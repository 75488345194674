import {listen} from "../lib/component.js";
import Template from "../lib/template.js";
import Form from "./_form.js";

export default class IndexForm extends Form {

	constructor($, $$, props) {

		super(...arguments, {template: new Template($$.template)});

		listen($$.canvas, "input", event => {
			const input = event.target;
			if(!(input.validity.valid && !!input.value.length)) {
				input.value = "";
			}
			const inputs = this.ƒƒ.quantity;
			const required = !inputs.some(input_ => input_.validity.valid && !!input_.value.length);
			inputs.forEach(input_ => input_.required = required);
		});

		listen($$.canvas, "animationend", event => {
			switch(event.animationName) {
				case "category-stack--slide-in":
					sleep(10, () => event.target.querySelector('input[name="quantity"]').focus());
					delete event.target.dataset.state;
					break;
				case "category-stack--shake":
					delete event.target.dataset.state;
					break;
			}
		});

		// @debug:
		listen(document, "keydown:f1", () => {
			this.ƒƒ.quantity[0].value = 2;
			this.ƒƒ.quantity[0].dispatchEvent(new Event("input", {bubbles: true}));
			this.ƒ.name.value =  "Testy McTestface";
			this.ƒ.email.value = "test@test.test";
			this.ƒ.agree.checked = true;
		});

	}

	async addCategory(category) {
		await this.template(this.$$.canvas, {...category, index: Date.now()}, "prepend");
	}

	serialize() {
		const ids = this.ƒƒ.id.map(input => String.fromInput(input));
		const quantities = this.ƒƒ.quantity.map(input => Number.fromInput(input));
		return {
			ticketQuantities:   Object.fromEntries(ids.map((id, i) => [id, quantities[i]])),
			name:               String.fromInput(this.ƒ.name),
			email:              String.fromInput(this.ƒ.email),
			invoiceName:        String.fromInput(this.ƒ.invoiceName),
			invoiceAddress:     String.fromInput(this.ƒ.invoiceAddress),
			invoiceCountryCode: String.fromInput(this.ƒ.invoiceCountryCode)
		}
	}

	async submit() {
		this.$$.button.setState("pending");
		try {
			await Promise.all([super.submit(), sleep(500)]);
			location.assign(new URL(`/orders/${this.data.id}`, location.origin));
		}
		finally {
			this.$$.button.removeState();
		}
	}

}
